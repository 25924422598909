import Vue from 'vue'
import VueRouter from 'vue-router'
import AddSeries from '@/views/AddSeries.vue'
import ExportSeries from '@/views/ExportSeries.vue'
import Login from '@/views/Login'
import Guns from "@/views/Guns.vue";
import Competitions from "@/views/Competitions.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: "Home",
        redirect: '/add-serie',
    },
    {
        path: '/add-serie',
        name: 'AddSeries',
        component: AddSeries,
    },
    {
        path: '/export-series',
        name: 'ExportSeries',
        component: ExportSeries,
    },
    {
        path: '/guns',
        name: 'Guns',
        component: Guns,
    },
    {
        path: '/competitions',
        name: 'Competitions',
        component: Competitions,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },

    /* PageNotFound Must be last!!!*/
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import(/*webpackChunkName "page_not_found"*/ '../views/PageNotFound'),
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router
