<template>
    <div>
        <v-select
            :items="available_locales"
            v-model="$root.$i18n.locale"
            @change="change_locale"
            dense
            flat
            solo
        >
            <template v-slot:selection="{ item }">
                <span
                    v-if="dense"
                    class="d-flex justify-end" style="width: 100%;">
                    <flag
                        v-if="locale_to_iso_3166_county_code_mapping_table[$root.$i18n.locale] !== '-'"
                        :iso="locale_to_iso_3166_county_code_mapping_table[$root.$i18n.locale]"
                        style="width: 24px; height: 24px"
                        class="mr-2"
                    />
                    <v-icon
                        v-else
                        class="mr-2"
                        v-text="'fa-flag'"
                    />
                    {{ item.value }}
                </span>
                <span
                    v-else
                >
                    <flag
                        v-if="locale_to_iso_3166_county_code_mapping_table[$root.$i18n.locale] !== '-'"
                        :iso="locale_to_iso_3166_county_code_mapping_table[$root.$i18n.locale]"
                        style="width: 24px; height: 24px"
                        class="mr-2"
                    />
                    <v-icon
                        v-else
                        class="mr-2"
                        v-text="'fa-flag'"
                    />
                    {{ item.text }}
                </span>
            </template>
            <template #item="{ item }">
                <div class="mr-4">
                    <flag
                        v-if="item.flag"
                        :iso="locale_to_iso_3166_county_code_mapping_table[item.value]"
                    />
                    <v-icon
                        v-else
                        v-text="'fa-flag'"
                        small
                    />
                </div>

                <div>
                    {{ item.text }}
                </div>
                <div
                    v-if="item.text!==item.value"
                    class="ml-3"
                >
                    ({{ item.value }})
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {locales_mixin} from '@/mixins/services/locales_mixin'

export default {
    name: "SelectLocale",
    props: {
        dense: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [locales_mixin],
    components: {},
    data() {
        return {
            // template: false,
        }
    },
    computed: {
        available_locales() {
            const available_locales = []

            for (let [locale, flag] of Object.entries(this.locale_to_iso_3166_county_code_mapping_table)) {
                available_locales.push({
                    text: this.translate('locale.' + locale),
                    value: locale,
                    flag: flag !== '-' ? flag : null,
                })
            }

            return available_locales
        },

        ...mapGetters([
            // ...
        ]),
        ...mapState([
            // ...
        ]),
    },
    watch: {
        // template(v) {
        //     do stuff;
        // }
    },
    methods: {
        change_locale() {
            localStorage.selected_locale = this.$root.$i18n.locale
        },

        ...mapActions([
            // ...
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>

<style scoped>

</style>