// todo translations

export const texts = {
    terms_en: [
        {
            header: '1. Company Information & Purpose of the Service',
        },
        {
            text: '1.1 U2Work is an internet-based web service, developed by TCB People & Organisation AB (“TCB AB”), which is aimed at organizations and / or individuals who want to develop their skills and receive support in looking for work. TCB People & Organisation AB is registered under organisation number 559215-9940.',
        },
        {
            header: '2. Applicability',
        },
        {
            text: '2.1 These terms of use contain the general terms and conditions under which TCB AB provides services on the website www.U2Work.se (the "Service"). The agreement applies between TCB AB and the physical or legal person who uses and has an account on the Service. Paying Customer has another applicable business agreement.',
        },
        {
            header: '3. Registration and authorization',
        },
        {
            text: "3.1 In order to be able to use TCB AB's services, the Customer or the User must provide contact information to TCB AB. The Customer / User is responsible for ensuring that the information provided is correct. The authorization is linked to the respective User.",
        },
        {
            header: '4. Intellectual property rights',
        },
        {
            text: '4.1 The material on U2Work is copyrighted material and may not be used without the permission of the author. Thus, users may not copy, distribute, commercially exploit, reproduce or otherwise benefit from such material.',
        },
        {
            header: '5. Disclaimer',
        },
        {
            text: '5.1 TCB AB cannot be held responsible for failure to deliver the Service if the failure is based on a Force Majeure event. By Force-Majeure is meant, among other things, but not exclusively; government action or omission, new or changed legislation, resignation, illness or other impairment of ability to work, death, conflict in the labor market, blockade, fire, flood, loss or destruction of data or property of significant importance or major accident.',
        },
        {
            text: '5.2 In addition to what is stated in law, TCB AB is not responsible for errors and omissions in the information provided through the Service and is thus not responsible for any direct or',
        },
        {
            text: 'indirect damages or losses that you may suffer in connection with, or due to your use of the Service. TCB AB is not liable for damage or inconvenience due to interruptions or other disruptions in telecommunications, computer, or other communication connections, computer equipment or computer systems, computer viruses, hacking or other security breaches, or other circumstances, regardless of whether these are within or outside TCB AB control.',
        },
        {
            header: '6. Applicable law, etc.',
        },
        {
            text: '6.1 Swedish law shall apply with respect to these terms of use. Any dispute is decided by an appropriate Swedish court.',
        },
    ],
    terms_sv: [
        {
            header: '1. Bolagsinformation & Syfte med Tjänsten',
        },
        {
            text: '1.1 U2Work är en internetbaserad webbtjänst, framtagen av TCB People & Organisation AB (“TCB AB”), som riktar sig till organisationer och / eller personer som vill utveckla sin förmåga och få stöd i att söka arbete. TCB People & Organisation AB är registrerat under organisationsnummer 559215-9940.',
        },
        {
            header: '2. Tillämplighet',
        },
        {
            text: '2.1 Dessa användarvillkor innehåller de allmänna villkor under vilken TCB AB tillhandahåller tjänster på webbplatsen www.U2Work.se (”Tjänsten”). Avtalet gäller mellan TCB AB och den fysiska eller juridiska person som använder och har ett konto på Tjänsten. Betalande Kund har ett annat tillämpligt affärsavtal.',
        },
        {
            header: '3. Registrering och behörighet',
        },
        {
            text: '3.1 För att kunna nyttja TCB AB:s tjänster krävs att Kunden eller Användaren lämnar kontaktuppgifter till TCB AB. Kunden / Användaren ansvarar för att lämnade uppgifter är riktiga. Behörigheten är kopplad till respektive Användare.',
        },
        {
            header: '4. Immaterialrätt',
        },
        {
            text: '4.1 Materialet på U2Work är upphovsrättsligt skyddat material och får inte användas utan tillstånd från upphovsmannen. Användarna kan alltså inte kopiera, distribuera, kommersiellt exploatera, reproducera eller på annat sätt dra nytta från sådant material.',
        },
        {
            header: '5. Ansvarsbefrielse',
        },
        {
            text: '5.1 TCB AB kan inte hållas ansvarigt för underlåtenhet att leverera Tjänsten om underlåtenheten har sin grund i Force Majeure-händelse. Med Force-Majeure menas bland annat, men inte uteslutande; myndighetsåtgärd eller underlåtenhet, nytillkommen eller ändrad lagstiftning, personalavgång, sjukdom eller annan nedsättning av arbetsförmåga, dödsfall, konflikt på arbetsmarknaden, blockad, brand, översvämning, förlust eller förstörelse av data eller egendom av väsentlig betydelse eller olyckshändelse av större omfattning.',
        },
        {
            text: '5.2 Utöver vad som framgår av lag ansvarar TCB AB inte för felaktigheter och brister i den information som tillhandahålls genom Tjänsten och ansvarar därmed inte för några direkta eller indirekta skador eller förluster som du kan komma att lida i anslutning till, eller på grund av din användning av, Tjänsten. TCB AB ansvarar inte för skada eller olägenhet som beror på driftavbrott eller andra störningar i tele-, dator-, eller annan kommunikationsförbindelse, datorutrustning eller datasystem, datorvirus, hackning eller andra säkerhetsintrång, eller annan omständighet, oavsett om dessa ligger inom eller utanför TCB AB:s kontroll.',
        },
        {
            header: '6. Tillämplig lag m.m.',
        },
        {
            text: '6.1 Svensk rätt ska vara tillämplig med avseende på dessa användarvillkor. Eventuell tvist avgörs av behörig svensk domstol.',
        },
    ],
}
