export const locales_mixin = {
    data() {
        return {
            locale_to_iso_3166_county_code_mapping_table: {
                sv: 'se',
                en: 'gb',
                am: 'et',
                ar: 'sa',
                az: 'az',
                bg: 'bg',
                bn: 'bd',
                bs: 'ba',
                ceb: '-',
                cs: 'cz',
                da: 'dk',
                de: 'de',
                el: 'gr',
                es: 'es',
                et: 'ee',
                fa: 'ir',
                fi: 'fi',
                fr: 'fr',
                hi: 'in',
                hr: 'hr',
                hu: 'hu',
                hy: 'am',
                id: 'id',
                ig: 'ng',
                is: 'is',
                it: 'it',
                ja: 'jp',
                jw: '-',
                ka: 'ge',
                kk: 'kz',
                km: 'kh',
                ko: 'kr',
                ku: '-',
                lt: 'lt',
                lv: 'lv',
                mg: 'mg',
                mk: 'mk',
                ml: '-',
                mr: '-',
                ms: '-',
                my: 'mm',
                ne: 'np',
                nl: 'nl',
                no: 'no',
                ny: '-',
                pa: '-',
                pl: 'pl',
                ps: '-',
                pt: 'pt',
                ro: 'ro',
                ru: 'ru',
                sd: '-',
                si: 'lk',
                sk: 'sk',
                sl: 'sl',
                so: 'so',
                sq: 'al',
                sr: 'sr',
                su: 'id',

                sw: '-',
                ta: '-',
                te: 'in',
                th: 'th',
                ti: '-',
                tl: '-',
                tr: 'tr',
                uk: 'ua',
                ur: '-',
                uz: 'uz',
                vi: 'vn',
                yo: '-',
                'zh-cn': 'cn',
                'zh-tw': 'tw',
            },
        }
    },
    methods: {
        translate_dict(locale_dict) {
            if (!locale_dict) {
                return `Locale dict not found`
            }

            const selected_locale = `locale_${this.$i18n.locale}`

            if (locale_dict[selected_locale]) {
                return locale_dict[selected_locale]
            }

            // const en_translation = locale_dict.locale_en
            // if() TODO: load from translations list

            return locale_dict.locale_fallback
        },
        translate(locale_key) {
            if (locale_key && this.$te(locale_key)) return this.$t(locale_key)
            return locale_key
        },
    },
}
