import { mapActions, mapGetters, mapState } from 'vuex'

export const utils_mixin = {
    data() {
        return {}
    },
    computed: {
        ...mapGetters({}),
        ...mapState({}),
    },
    methods: {
        deep_get(dotted_path, object) {
            const path = dotted_path.split('.')
            const [key] = path
            if (path.length === 1) {
                return object[key]
            } else {
                path.splice(0, 1)
                const new_dotted_path = path.join('.')
                return this.deep_get(new_dotted_path, object[key])
            }
        },
        ...mapActions([]),
    },
}
