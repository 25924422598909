<template>
    <section id="login_view">
        <v-row
            justify="center"
            no-gutters
        >
            <v-card
                width="100%"
                max-width="500px"
                elevation="6"
                class="ma-4"
                rounded
            >
                <v-toolbar
                    color="blue darken-3"
                    dark
                >
                    <v-toolbar-title>{{ translate('auth.title') }}</v-toolbar-title>
                </v-toolbar>
                <v-card-title>
                    {{ translate('auth.sign_in') }}
                    <v-spacer/>
                    <SelectLocale dense/>
                </v-card-title>

                <v-tooltip
                    v-for="item in oauth_authenticators"
                    :key="item.value"
                    bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click="redirect_for_oauth_login(item.value)"
                            color="primary"
                            class="ml-4 mb-4"
                            fab
                            large
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ item.title }}</span>
                </v-tooltip>
                <v-divider class="mt-4 mx-4"/>
                <v-card-subtitle>{{ translate('auth.email_authentication') }}</v-card-subtitle>
                <v-card
                    class="px-4"
                    color="transparent"
                    outlined
                >
                    <v-text-field
                        :label="translate('auth.email')"
                        @keyup.enter="send_email"
                        v-model="email"
                        prepend-icon="fa-at"
                        class="mt-2"
                        hide-details
                        autofocus
                        outlined
                        dense
                    />
                    <v-checkbox
                        :label="translate('auth.remember_email')"
                        v-model="remember_email"
                    />
                    <v-btn
                        @click="send_email"
                        :disabled="!valid_email"
                        color="primary"
                    >
                        {{ translate('auth.send_email') }}
                    </v-btn>

                    <v-dialog
                        v-model="email_sent"
                        max-width="400px"
                        width="100%"
                        class="mx-4 mt-4 mb-12"
                        persistent
                    >
                        <template #default>
                            <v-card>
                                <v-card-title>
                                    <v-row
                                        no-gutters
                                        justify="space-between"
                                        align="center"
                                    >
                                        {{ translate('auth.verify_code') }}
                                        <v-btn
                                            @click="email_sent = false"
                                            icon
                                        >
                                            <v-icon v-text="'mdi-close'"/>
                                        </v-btn>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                    <v-row
                                        no-gutters
                                    >
                                        {{ translate('auth.verify_code_help') }}
                                    </v-row>
                                    <v-text-field
                                        ref="email_code"
                                        v-model="email_code"
                                        :label="translate('auth.email_code')"
                                        @keyup.enter="validate_email_code"
                                        prepend-icon="fa-lock"
                                        autocomplete="one-time-code"
                                        placeholder="* * * * * *"
                                        hint="6 siffror"
                                        persistent-hint
                                        class="mt-4"
                                        autofocus
                                        outlined
                                        dense
                                    />
                                </v-card-text>
                                <v-card-actions>
                                    <v-row
                                        no-gutters
                                        justify="end"
                                    >
                                        <v-btn
                                            :disabled="email_code.length !== 6"
                                            color="primary"
                                            @click="validate_email_code"
                                        >
                                            {{ translate('auth.sign_in') }}
                                        </v-btn>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-card>

                <v-divider class="mt-4 mx-4"/>


            </v-card>
        </v-row>
        <LoginDialog
            :dialog="dialog"
            @close="dialog = ''"
        />
    </section>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {api_mixin} from '@/mixins/services/api_mixin'
import {locales_mixin} from '@/mixins/services/locales_mixin'
import LoginDialog from '@/components/login_dialog'
import SelectLocale from "@/components/user_settings/select_locale.vue";

export default {
    name: 'Login',
    props: {},
    mixins: [api_mixin, locales_mixin],
    components: {
        SelectLocale,
        LoginDialog,
    },
    data() {
        return {
            internal_value: undefined,

            dialog: '',

            email_sent: false,
            email_regex:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            email: '',
            remember_email: true,
            email_code: '',

            oauth_authenticators: [
                {
                    title: 'Google',
                    icon: 'fab fa-google',
                    value: 'google',
                },
                {
                    title: 'Microsoft',
                    icon: 'fab fa-microsoft',
                    value: 'microsoft',
                },
            ],
        }
    },
    computed: {
        valid_email() {
            return !this.email.length
                ? false
                : this.email_regex.test(this.email)
        },
        ...mapGetters([]),
        ...mapState([
            'token',
        ]),
    },
    watch: {
        token() {
            this.navigate_to_home_if_authorized()
        },
        email_sent() {
            this.email_code = ''
        },
    },
    methods: {
        navigate_to_home_if_authorized() {
            if (this.token) {
                this.$router.push({
                    name: 'Home',
                })
            }
        },
        redirect_for_oauth_login(authenticator) {
            const oauth_uri = `authenticates/oauth2/${authenticator}/${process.env.VUE_APP_SYSTEM}/${process.env.VUE_APP_NAME}`
            window.location.replace(process.env.VUE_APP_API_ROOT + oauth_uri)
        },
        send_email() {
            if (!this.valid_email) return
            if (this.remember_email) {
                localStorage['email'] = this.email.toLowerCase()
            } else {
                delete localStorage['email']
            }
            this.email_sent = true
            this.$nextTick(() => {
                this.$refs.email_code.focus()
            })

            const url = `authenticates/email/${process.env.VUE_APP_SYSTEM}/${process.env.VUE_APP_NAME}/${this.email}`
            this.api_get({url})
        },
        async validate_email_code() {
            if (this.email_code.length !== 6) return
            window.location.replace(
                `${process.env.VUE_APP_API_ROOT}authenticates/email/${process.env.VUE_APP_SYSTEM}/${process.env.VUE_APP_NAME}/${this.email}/${this.email_code}`
            )
        },
        open_dialog(dialog) {
            this.dialog = dialog
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.navigate_to_home_if_authorized()
    },
    beforeMount() {
    },
    mounted() {
        if (localStorage['email']) {
            this.email = localStorage['email']
        }
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>

</style>
