import { format, getISODay, getWeek, isValid, sub } from 'date-fns'

export const date_mixin = {
    methods: {
        format_date(value) {
            const date = new Date(value)
            return value && isValid(date) ? format(date, 'yyyy-MM-dd') : value
        },
        format_date_time(value) {
            const date = new Date(value)
            return value && isValid(date) ? format(date, 'yyyy-MM-dd HH:mm') : value
        },
        format_time(value) {
            const date = new Date(value)
            return value && isValid(date) ? format(date, 'HH:mm') : value
        },
        calculate_past_weeks(count) {
            const weeks = []
            const current_date = new Date()
            let current_year = current_date.getFullYear()
            const first_january = getISODay(new Date(current_year, 0, 1))
            for (let i = 0; i < count; i++) {
                const date = sub(new Date(), { weeks: i })
                const week = getWeek(date, {
                    weekStartsOn: 1,
                    firstWeekContainsDate: first_january,
                })
                const [previous] = weeks
                if (previous && previous.week < week) {
                    current_year--
                }
                weeks.unshift({
                    week,
                    year: current_year,
                })
            }
            return weeks
        },
    },
}
