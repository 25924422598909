<template>
    <v-dialog
        :value="dialog"
        persistent
    >
        <v-card tile>
            <v-card-title>
                <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                >
                    {{
                        dialog === 'gdpr'
                            ? translate('gdpr')
                            : translate('terms')
                    }}

                    <v-btn
                        @click="$emit('close')"
                        icon
                    >
                        <v-icon v-text="'mdi-close'" />
                    </v-btn>
                </v-row>
            </v-card-title>
            <v-card-subtitle class="pt-2">
                {{
                    dialog === 'gdpr'
                        ? translate('gdpr_description')
                        : translate('terms_description')
                }}
            </v-card-subtitle>
            <v-btn
                @click="language = 'sv'"
                style="font-size: 2.5rem; overflow: hidden"
                class="ml-4 mb-4 mr-2"
                small
                fab
            >
                <flag iso="se" />
            </v-btn>

            <v-btn
                @click="language = 'en'"
                style="font-size: 2.5rem; overflow: hidden"
                class="mb-4"
                small
                fab
            >
                <flag iso="gb" />
            </v-btn>
            <component
                v-for="(text, i) in texts"
                :key="i"
                :is="text.component"
                :class="{
                    'font-weight-bold': text.header,
                    'pb-2': text.header,
                }"
            >
                {{ text.value }}
            </component>
            <v-card-text v-if="dialog === 'gdpr'">
                Work in progress...
            </v-card-text>
            <v-card-text class="text-center mt-8">
                TCB People & Organisation AB<br />
                Org.nr 559215-9940<br />
                <a href="mailto:info@tcbab.se">info@tcbab.se</a>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { texts } from './texts'
import { VCardSubtitle, VCardText } from 'vuetify/lib'
import { locales_mixin } from '@/mixins/services/locales_mixin'

export default {
    name: 'LoginDialog',
    props: {
        dialog: {
            type: String,
        },
    },
    mixins: [locales_mixin],
    components: {
        VCardText,
        VCardSubtitle,
    },
    data() {
        return {
            texts: [],
            language: 'en',
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        language() {
            this.renderText()
        },
        dialog(value) {
            if (value) {
                this.renderText()
            }
        },
    },
    methods: {
        renderText() {
            this.texts = []
            if (!this.dialog || this.dialog === 'gdpr') return
            this.texts = texts[`${this.dialog}_${this.language}`].map((v) => {
                if (v.header) {
                    return {
                        component: VCardText,
                        value: v.header,
                        header: true,
                    }
                } else {
                    return {
                        component: VCardText,
                        value: v.text,
                    }
                }
            })
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        if (this.$i18n.locale === 'sv') {
            this.language = 'sv'
        } else {
            this.language = 'en'
        }
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped lang="sass"></style>
