<template>
    <v-footer color="white">
        <v-card
            color="transparent"
            width="100%"
            flat
            tile
        >
            <v-card-text class="caption ma-0 pa-2">
                <v-row
                    no-gutters
                    align="center"
                    justify="center"
                >
                    <div v-text="'Wiik'"/>
                    <v-icon
                        v-text="'mdi-copyright'"
                        class="px-1"
                        small
                    />
                    <div v-text="new Date().getFullYear()"/>
                </v-row>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {locales_mixin} from '@/mixins/services/locales_mixin'

export default {
    name: 'TheFooter',
    props: {},
    mixins: [locales_mixin],
    components: {},
    data() {
        return {
            icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram'],
        }
    },
    computed: {
        app_name() {
            return process.env.VUE_APP_NAME
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped></style>
