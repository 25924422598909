<template>
    <div>
        <div
            v-if="is_loaded"
        >
            <v-row
                align="center"
                justify="center"
                dense
            >
                <v-card outlined color="transparent">
                    <v-row align="center" no-gutters>
                        <v-col cols="8" class="">
                            <v-autocomplete
                                v-model="new_serie.gun"
                                :label="translate('app.gun')"
                                dense
                                outlined
                                :items="gunItems"
                            />
                        </v-col>
                        <v-col cols="4" class="">
                            <v-checkbox v-model="competition" class="align-self-center mb-5 ml-5 pr-0 mr-0"/>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
            <v-row
                v-if="competition"
                align="center"
                justify="center"
                dense
            >
                <v-card outlined color="transparent">
                    <v-autocomplete
                        v-model="new_serie.competition"
                        :label="translate('app.competition')"
                        dense
                        outlined
                        clearable
                        :items="competitionItems"
                    />
                </v-card>
            </v-row>
            <div
                v-if="new_serie.gun.length>0"
            >

                <v-row
                    v-if="competition"
                    align="center"
                    justify="center"
                    dense
                >
                    <v-card
                        elevation="2"
                        variant="outlined"
                    >
                        <v-card-text
                            class="text-center text-h2"
                        >
                            {{ formattedTime }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="startStopwatch" :disabled="isRunning" color="green">{{
                                    translate('app.start')
                                }}
                            </v-btn>
                            <v-btn @click="stopStopwatch" :disabled="!isRunning" color="yellow">{{
                                    translate('app.stop')
                                }}
                            </v-btn>
                            <v-btn @click="resetStopwatch" :disabled="isRunning" color="red">{{
                                    translate('app.reset')
                                }}
                            </v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-row>
                <v-row
                    v-if="competition"
                    align="center"
                    justify="center"
                    class="mt-2"
                >
                    &nbsp;
                </v-row>

                <v-row
                    align="center"
                    justify="center"
                    class="ma-1 mt-10"
                    v-for="(item, i) in ['shot_one', 'shot_two', 'shot_three', 'shot_four', 'shot_five']"
                    v-bind:key="i"
                >
                    <v-slider
                        v-model="new_serie[item].score"
                        @change="sliderChangeValue(item)"
                        light
                        ticks="always"
                        :tick-labels="['','','','','','','','7','8','9','']"
                        height="5"
                        :label="(i+1).toString()"
                        thumb-color="primary"
                        thumb-label="always"
                        min="0"
                        max="10"
                    />
                    <v-checkbox
                        v-model="new_serie[item].bullseye"
                        :disabled="new_serie[item].score!==10"
                        class="ma-0"
                    />
                </v-row>
                <v-row
                    align="center"
                    justify="center"
                    class="mt-2"
                >
                    <v-card width="100%">
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                @click="addSerie"
                                color="primary"
                            >
                                {{ translate('app.add_serie') }}
                            </v-btn>
                            <v-spacer/>
                            <h4 class="mr-3">{{ score }}</h4>
                            <i
                                v-if="show_max_score"
                                class="mr-3">
                                ({{ max_score }})
                            </i>
                        </v-card-actions>
                    </v-card>

                </v-row>
            </div>

        </div>
        <div
            v-else
        >
            <v-row
                align="center"
                justify="center"
                dense
                class="mt-12"
            >
                <v-card outlined color="transparent">
                    {{ translate('app.loading') }}...
                </v-card>
                <v-progress-linear
                    indeterminate
                    color="primary"
                    class="mt-5"
                />
            </v-row>
        </div>
    </div>

</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {api_mixin} from '@/mixins/services/api_mixin'
import {date_mixin} from '@/mixins/services/date_mixin'
import {locales_mixin} from '@/mixins/services/locales_mixin'

export default {
    name: 'AddSeries',
    props: {},
    mixins: [api_mixin, date_mixin, locales_mixin],
    components: {},
    data() {
        return {
            shot_properties: ['shot_one', 'shot_two', 'shot_three', 'shot_four', 'shot_five'],
            isRunning: false,
            startTime: null,
            elapsedTime: 0,
            intervalId: null,
            gunItems: [],
            competitionItems: [],
            gunsLoaded: false,
            apiLoaded: false,
            new_serie: {
                gun: ""
            },
            score: 0,
            max_score: 50,
            show_max_score: true,
            competition: false,
        }
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.elapsedTime / 60000)
            const seconds = Math.floor((this.elapsedTime % 60000) / 1000)

            return `${minutes.toString().padStart(2, '0')}:
              ${seconds.toString().padStart(2, '0')}`
        },
        is_loaded() {
            return this.apiLoaded && this.gunsLoaded
        },

        ...mapGetters(['decoded_token']),
        ...mapState(['guns']),
    },
    watch: {},
    methods: {
        startStopwatch() {
            if (!this.isRunning) {
                this.startTime = Date.now() - this.elapsedTime
                this.intervalId = setInterval(this.updateElapsedTime, 1000)
                this.isRunning = true
            }
        },
        stopStopwatch() {
            if (this.isRunning) {
                clearInterval(this.intervalId)
                this.intervalId = null
                this.isRunning = false
            }
        },
        resetStopwatch() {
            if (this.isRunning) {
                this.stopStopwatch()
            }
            this.elapsedTime = 0
        },
        updateElapsedTime() {
            this.elapsedTime = Date.now() - this.startTime
        },
        sliderChangeValue(item) {
            if (this.isRunning) {
                this.new_serie[item].seconds = Math.floor(this.elapsedTime / 1000)
            }
            this.score = 0
            this.shot_properties.forEach(item => this.score += this.new_serie[item].score)
            this.calculate_max_score()
        },
        calculate_max_score() {
            this.show_max_score = true
            if (this.new_serie.shot_five.score > 0) {
                this.show_max_score = false
                this.max_score = this.score
            } else if (this.new_serie.shot_four.score > 0) {
                this.max_score = this.score + 10
            } else if (this.new_serie.shot_three.score > 0) {
                this.max_score = this.score + 10 * 2
            } else if (this.new_serie.shot_two.score > 0) {
                this.max_score = this.score + 10 * 3
            } else if (this.new_serie.shot_one.score > 0) {
                this.max_score = this.score + 10 * 4
            } else {
                this.max_score = 50
            }
        },
        resetNewSerie() {
            this.resetStopwatch()
            this.score = 0
            this.max_score = 50
            this.show_max_score = true
            this.shot_properties.forEach(item => this.new_serie[item] = {score: 0, bullseye: false})
        },
        async addSerie() {
            this.new_serie.user = this.decoded_token.u_id
            if (this.new_serie.competition === null) {
                delete this.new_serie.competition
            }
            this.new_serie.summary = this.score
            let number_bullseye = 0
            this.shot_properties.forEach(item => {
                if (this.new_serie[item].bullseye) {
                    number_bullseye += 1
                }
            })
            this.new_serie.number_bullseye = number_bullseye
            await this.api_post({
                url: 'x-eld--my-shooting-series',
                data: this.new_serie,
            })
            this.resetNewSerie()
        },
        async load_guns() {
            const result = await this.api_get({
                url: 'x-eld--my-guns',
                params: {
                    where: {
                        user: this.decoded_token.u_id
                    },
                    sort: "name",
                    max_results: 50,
                },
                commit: true,
                property: "guns",
            })
            result.data._items.forEach(item => this.gunItems.push({text: item.name, value: item._id}))
            this.gunsLoaded = true
        },
        async load_competitions() {
            const result = await this.api_get({
                url: 'x-eld--my-competitions',
                params: {
                    where: {
                        user: this.decoded_token.u_id
                    },
                    sort: "name",
                    max_results: 50,
                },
                commit: true,
                property: "competitions",
            })
            result.data._items.forEach(item => this.competitionItems.push({text: item.name, value: item._id}))
        },
        async load_api() {
            await this.api_get({
                url: 'version',
                params: {},
            })
            this.apiLoaded = true
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    async beforeMount() {
        this.resetNewSerie()
        await this.load_api()
        await this.load_guns()
        await this.load_competitions()
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped lang="sass">
::v-deep tbody tr:hover
    cursor: pointer
</style>
