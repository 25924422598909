import Vue from 'vue'
import Vuex from 'vuex'
import VueJwtDecode from 'vue-jwt-decode'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

function restore_state_function(key, storage) {
    let state = storage.getItem(key)
    if (!state || !state.length) return null

    state = JSON.parse(state)

    if (!state.expires || state.expires < Date.now()) {
        console.log('state has expired!!!!')
        return null
    }

    return state.value
}

function save_state_function(key, state, storage, duration_ms) {
    const now = Date.now()
    let expires = null
    let existing_state = storage.getItem(key)
    if (existing_state) {
        existing_state = JSON.parse(existing_state)
        expires = existing_state.expires > now ? existing_state.expires : null
    }

    if (!expires) {
        expires = now + duration_ms
    }

    const state_to_save = {
        value: state,
        expires: expires,
    }
    storage.setItem(key, JSON.stringify(state_to_save))
}

const vuex_persist_forever = new VuexPersistence({
    key: 'vuex_persist_forever',
    storage: window.localStorage,
    reducer: (state) => ({
        token: state.token,
    }),
})

const vuex_persist_one_day = new VuexPersistence({
    key: 'vuex_persist_one_day',
    storage: window.localStorage,
    reducer: (state) => ({
        dummy: state.dummy,
    }),
    saveState: (key, state, storage) => {
        const expires = 1000 * 60 * 60 * 24
        return save_state_function(key, state, storage, expires)
    },
    restoreState: restore_state_function,
})

const vuex_persist_48h = new VuexPersistence({
    key: 'vuex_persist_48h',
    storage: window.localStorage,
    reducer: (state) => ({
        media_files: state.media_files,
    }),
    saveState: (key, state, storage) => {
        const expires = 1000 * 60 * 60 * 48
        return save_state_function(key, state, storage, expires)
    },
    restoreState: restore_state_function,
})

export default new Vuex.Store({
    state: {
        token: null,
        token_has_expired: false,
        user_extended_data: {},
        user_extended_data_loaded: false,
        guns: [],
        competitions: [],
        locales:[
            "sv",
            "en",
        ]
    },
    getters: {
        decoded_token: (state) => {
            if (!state.token) return {}
            return VueJwtDecode.decode(state.token)
        },
    },
    mutations: {
        mutate(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property] = payload.data
            } else {
                state[payload.property] = payload.data
            }
        },
        mutate_key(state, payload) {
            if (payload.module) {
                Vue.set(state[payload.module][payload.property], payload.key, payload.data)
            } else {
                Vue.set(state[payload.property], payload.key, payload.data)
            }
        },
        push(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property].push(payload.data)
            } else {
                state[payload.property].push(payload.data)
            }
        },
        splice(state, payload) {
            state[payload.property].splice(payload.index, 1)
        },
        concatenate(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property] = state[payload.module][payload.property].concat(payload.data)
            } else {
                state[payload.property] = state[payload.property].concat(payload.data)
            }
        },
    },
    actions: {
        set_state_property({ commit }, payload) {
            commit('mutate', {
                property: payload.property,
                data: payload.data,
                module: payload.module,
            })
        },
        update_key_in_state_property({ commit }, payload) {
            commit('mutate_key', {
                property: payload.property,
                data: payload.data,
                key: payload.key,
                module: payload.module,
            })
        },
        set_state_properties({ commit }, payloads) {
            payloads.forEach((payload) => {
                commit('mutate', {
                    property: payload.property,
                    data: payload.data,
                    module: payload.module,
                })
            })
        },
        push_state_property({ commit }, payload) {
            commit('push', {
                property: payload.property,
                data: payload.data,
                module: payload.module,
            })
        },
        splice_state_property({ commit }, payload) {
            commit('splice', {
                property: payload.property,
                index: payload.index,
            })
        },
        concatenate_state_property({ commit }, payload) {
            commit('concatenate', {
                property: payload.property,
                data: payload.data,
                module: payload.module,
            })
        },
    },
    modules: {},
    plugins: [vuex_persist_forever.plugin, vuex_persist_one_day.plugin, vuex_persist_48h.plugin],
})
