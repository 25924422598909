<template>
    <v-container style="max-width: 500px; position: relative;">
        <div
            v-if="is_loaded"
        >
            <v-row
                class="mt-4"
                align="center"
                justify="center"
            >
                <v-autocomplete
                    v-model="gun"
                    :label="translate('app.gun')"
                    dense
                    outlined
                    clearable
                    :items="gunItems"
                />
            </v-row>
            <v-row
                align="center"
                justify="center"
                dense
            >
                <v-menu
                    v-model="menu_from_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="from_date"
                            :label="translate('app.from_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="from_date"
                        @input="menu_from_date = false"
                    />
                </v-menu>
            </v-row>
            <v-row
                class="mt-4"
                align="center"
                justify="center"
            >
                <v-menu
                    v-model="menu_to_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="to_date"
                            :label="translate('app.to_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="to_date"
                        @input="menu_to_date = false"
                    />
                </v-menu>
            </v-row>
            <v-row
                class="mt-4"
                align="center"
                justify="center"
            >
                <v-checkbox
                    v-model="include_daily_summary"
                    :label="translate('app.include_daily_summary')"
                />
            </v-row>

            <v-row
                class="mt-4"
                align="center"
                justify="center"
            >
                <v-checkbox
                    v-model="include_details"
                    :label="translate('app.include_details')"
                />
            </v-row>

            <v-row
                class="mt-12"
                align="center"
                justify="center"
            >
                <v-btn
                    color="primary"
                    @click="startExport()"
                >
                    {{ translate('app.start_export') }}
                </v-btn>
            </v-row>
        </div>
        <div
            v-else
        >
            <v-row
                align="center"
                justify="center"
                dense
                class="mt-12"
            >
                <v-card outlined color="transparent">
                    {{ translate('app.loading') }}
                </v-card>
                <v-progress-linear
                    indeterminate
                    color="primary"
                    class="mt-5"
                />
            </v-row>
        </div>

        <v-dialog
            v-model="continue_to_fetch"
            persistent
            width="500"
        >
            <v-card
                class="text-center"
                justify="center"
            >
                <v-card-title class="text-h5 grey lighten-2">
                    {{ translate('app.downloading') }}
                </v-card-title>

                <v-divider></v-divider>
                <v-progress-circular
                    class="ma-12"
                    :rotate="360"
                    :size="100"
                    :width="15"
                    :value="progress"
                    color="teal"
                >
                    {{ progress }}
                </v-progress-circular>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        @click="cancelDownload()"
                    >
                        {{ translate('app.cancel') }}
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {api_mixin} from '@/mixins/services/api_mixin'
import {date_mixin} from '@/mixins/services/date_mixin'
import {locales_mixin} from '@/mixins/services/locales_mixin'

export default {
    name: 'ExportSeries',
    props: {},
    mixins: [api_mixin, date_mixin, locales_mixin],
    components: {},
    data() {
        return {
            to_date: "",
            from_date: "",
            menu_from_date: false,
            menu_to_date: false,
            include_daily_summary: true,
            include_details: false,
            gun: null,
            gunItems: [],
            items: [],
            continue_to_fetch: false,
            download_is_cancel: false,
            total: undefined,
            apiLoaded: false,
        }
    },
    computed: {
        progress() {
            if (this.total && this.total > 0) {
                return Math.round(100 * this.items.length / this.total)
            }
            return 0
        },
        is_loaded() {
            return this.apiLoaded
        },
        ...mapGetters(['decoded_token']),
        ...mapState([]),
    },
    watch: {},
    methods: {
        cancelDownload() {
            this.continue_to_fetch = false
            this.download_is_cancel = true
        },
        async load_guns() {
            const result = await this.api_get({
                url: 'x-eld--my-guns',
                params: {
                    where: {
                        user: this.decoded_token.u_id
                    },
                    sort: "name",
                    max_results: 50,
                },
                commit: true,
                property: "guns",
            })
            result.data._items.forEach(item => this.gunItems.push({text: item.name, value: item._id}))
        },
        async load_all_series() {
            let from = new Date(this.from_date)
            let to = new Date(this.to_date)
            let where = {
                user: this.decoded_token.u_id,
                _created: {
                    $gte: from.toUTCString(),
                    $lt: to.toUTCString()
                }
            }
            if (this.gun) {
                where.gun = this.gun
            } else {
                delete where.gun
            }

            let page = 1
            let max_results = 50
            this.items = []

            this.total = undefined
            this.continue_to_fetch = true

            while (this.continue_to_fetch) {
                let request = await this.api_get({
                    url: 'x-eld--my-shooting-series',
                    params: {
                        where: where,
                        sort: "-_created",
                        page: page,
                        max_results: max_results
                    },
                })
                if (this.total === undefined) {
                    this.total = request.data['_meta']['total']
                }

                this.items = this.items.concat(request.data['_items'])
                if (this.items.length >= this.total) {
                    this.continue_to_fetch = false
                } else {
                    page += 1
                }
            }
        },
        async startExport() {
            this.download_is_cancel = false
            await this.load_all_series()
            if (!this.download_is_cancel) {
                await this.downloadCSVData()
            }
        },
        async downloadCSVData() {
            let csv = ''
            if (this.include_details) {
                csv = 'Date,Gun,Competition,Sum,Bullseye,Shot 1,Shot 1 - Seconds,Shot 1 - Bullseye,Shot 2,Shot 2 - Seconds,Shot 2 - Bullseye,Shot 3,Shot 3 - Seconds,Shot 3 - Bullseye,Shot 4,Shot 4 - Seconds,Shot 4 - Bullseye,Shot 5,Shot 5 - Seconds,Shot 5 - Bullseye\n';
            } else {
                csv = 'Date,Gun,Competition,Sum,\n';
            }
            let last_date = ""
            let current_date = ""
            let total_summary = {}
            let day_summary = {}
            this.items.forEach((item) => {
                current_date = (new Date(item._created)).toISOString().slice(0, 10)
                if (last_date.length > 0 && current_date !== last_date) {
                    if (this.include_daily_summary && this.include_details) {
                        csv += this.format_day_summary_details(last_date, day_summary)
                    } else if (this.include_daily_summary) {
                        csv += this.format_day_summary(last_date, day_summary)
                    }
                    day_summary = {}
                }
                last_date = current_date
                total_summary = this.do_summary(total_summary, item)
                day_summary = this.do_summary(day_summary, item)
                if (this.include_details) {
                    csv += [
                        (new Date(item._created)).toISOString().replace("T", " ").replace(".000Z", ""),
                        item.gun_name,
                        item.competition_name,
                        item.summary,
                        item.number_bullseye,
                        (item.shot_one) ? item.shot_one.score : '',
                        (item.shot_one) ? this.formatted_time(item.shot_one.seconds) : '',
                        (item.shot_one) ? item.shot_one.bullseye : '',
                        (item.shot_two) ? item.shot_two.score : '',
                        (item.shot_two) ? this.formatted_time(item.shot_two.seconds) : '',
                        (item.shot_two) ? item.shot_two.bullseye : '',
                        (item.shot_three) ? item.shot_three.score : '',
                        (item.shot_three) ? this.formatted_time(item.shot_three.seconds) : '',
                        (item.shot_three) ? item.shot_three.bullseye : '',
                        (item.shot_four) ? item.shot_four.score : '',
                        (item.shot_four) ? this.formatted_time(item.shot_four.seconds) : '',
                        (item.shot_four) ? item.shot_four.bullseye : '',
                        (item.shot_five) ? item.shot_five.score : '',
                        (item.shot_five) ? this.formatted_time(item.shot_five.seconds) : '',
                        (item.shot_five) ? item.shot_five.bullseye : '',
                    ].join(',')
                } else {
                    csv += [
                        (new Date(item._created)).toISOString().replace("T", " ").replace(".000Z", ""),
                        item.gun_name,
                        item.competition_name,
                        item.summary,
                        ' ',
                        (item.shot_one) ? (item.shot_one.bullseye) ? ' X ' : ' ' + item.shot_one.score + ' ' : ' - ',
                        (item.shot_two) ? (item.shot_two.bullseye) ? 'X ' : item.shot_two.score + ' ' : '- ',
                        (item.shot_three) ? (item.shot_three.bullseye) ? 'X ' : item.shot_three.score + ' ' : '- ',
                        (item.shot_four) ? (item.shot_four.bullseye) ? 'X ' : item.shot_four.score + ' ' : '- ',
                        (item.shot_five) ? (item.shot_five.bullseye) ? 'X ' : item.shot_five.score + ' ' : '- ',
                    ].join(',')

                }
                csv += "\n"
            })
            if (this.items.length > 0) {
                if (this.include_details) {
                    if (this.include_daily_summary) {
                        csv += this.format_day_summary_details(last_date, day_summary)
                    }
                    csv += this.format_day_summary_details("", total_summary)
                } else {
                    if (this.include_daily_summary) {
                        csv += this.format_day_summary(last_date, day_summary)
                    }
                    csv += this.format_day_summary("", total_summary)

                }
            }

            const anchor = document.createElement('a')
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
            anchor.target = '_blank'
            let filename = new Date().toISOString()
            anchor.download = filename + '_precision.csv'
            anchor.click()
        },
        do_summary(obj, item) {
            if (obj[item.gun_name] === undefined) {
                obj[item.gun_name] = {
                    count: 0,
                    sum_summary: 0,
                    sum_shot_one: 0,
                    sum_shot_two: 0,
                    sum_shot_three: 0,
                    sum_shot_four: 0,
                    sum_shot_five: 0,
                }
            }
            obj[item.gun_name].count += 1
            obj[item.gun_name].sum_summary += item.summary
            obj[item.gun_name].sum_shot_one += (item.shot_one) ? item.shot_one.score : 0
            obj[item.gun_name].sum_shot_two += (item.shot_two) ? item.shot_two.score : 0
            obj[item.gun_name].sum_shot_three += (item.shot_three) ? item.shot_three.score : 0
            obj[item.gun_name].sum_shot_four += (item.shot_four) ? item.shot_four.score : 0
            obj[item.gun_name].sum_shot_five += (item.shot_five) ? item.shot_five.score : 0

            return obj
        },
        format_day_summary(last_date, day_summary) {
            let csv_section = "\n"
            Object.keys(day_summary).forEach(function (gun) {
                let d = day_summary[gun]
                csv_section += [
                    last_date,
                    gun,
                    '',
                    Math.round(100 * d.sum_summary / d.count) / 100,
                ].join(',')
                csv_section += "\n"
            })
            csv_section += "\n\n"

            return csv_section
        },
        format_day_summary_details(last_date, day_summary) {
            let csv_section = "\n"
            Object.keys(day_summary).forEach(function (gun) {
                let d = day_summary[gun]
                csv_section += [
                    last_date,
                    gun,
                    '',
                    Math.round(100 * d.sum_summary / d.count) / 100,
                    '',
                    Math.round(100 * d.sum_shot_one / d.count) / 100,
                    '',
                    '',
                    Math.round(100 * d.sum_shot_two / d.count) / 100,
                    '',
                    '',
                    Math.round(100 * d.sum_shot_three / d.count) / 100,
                    '',
                    '',
                    Math.round(100 * d.sum_shot_four / d.count) / 100,
                    '',
                    '',
                    Math.round(100 * d.sum_shot_five / d.count) / 100,
                ].join(',')
                csv_section += "\n"
            })
            csv_section += "\n\n"

            return csv_section
        },
        formatted_time(total_seconds) {
            if (total_seconds === undefined) {
                return ""
            }
            let minutes = Math.floor(total_seconds / 60)
            let seconds = Math.floor((total_seconds % 60))

            return minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0')
        },
        async load_api() {
            await this.api_get({
                url: 'version',
                params: {},
            })
            this.apiLoaded = true
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    async beforeMount() {
        await this.load_api()
        await this.load_guns()
    },
    mounted() {
        let now = new Date()
        now = new Date(now.getFullYear(), now.getMonth() + 1, 1, 23, 59, 59)
        this.to_date = now.toISOString().slice(0, 10)
        this.from_date = new Date(now.getFullYear() - 5, now.getMonth() - 1).toISOString().slice(0, 10)
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped lang="sass">
::v-deep tbody tr:hover
    cursor: pointer
</style>
