<template>
    <v-navigation-drawer
        app
        :right="$vuetify.rtl"
        v-model="internal_value"
    >
        <div style="height: calc(100% - 50px)">
            <v-toolbar flat>
                {{ decoded_token.u_name }}
                <v-spacer/>
                <v-btn
                    @click="internal_value = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-list class="pa-0">
                <v-divider/>
                <v-list-item
                    v-for="(page, index) in pages"
                    :key="index"
                    :to="page.to"
                    color="primary"
                >
                    <v-list-item-icon>
                        <v-icon>{{ page.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ translate(page.text) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider
                class="mt-3 mb-5"
            />
            <SelectLocale/>
        </div>
        <v-divider/>
        <v-card
            color="transparent"
            height="48"
            class="d-flex justify-center align-center"
            outlined
        >
            <v-btn
                color="primary"
                @click="logout"
                text
            >
                {{ translate('auth.sign_out') }}
            </v-btn>
        </v-card>
    </v-navigation-drawer>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import SelectLocale from "@/components/user_settings/select_locale.vue";
import {locales_mixin} from "@/mixins/services/locales_mixin";

export default {
    name: 'TheNavigationDrawer',
    props: {
        value: {
            type: Boolean,
        },
    },
    mixins: [locales_mixin],
    components: {SelectLocale},
    data() {
        return {
            pages: [
                {
                    to: '/',
                    icon: 'mdi-home',
                    text: 'app.add_serie',
                },
                {
                    to: '/export-series',
                    icon: 'mdi-list-box',
                    text: 'app.export',
                },
                {
                    to: 'guns',
                    icon: 'mdi-pistol',
                    text: 'app.guns',
                },
                {
                    to: 'competitions',
                    icon: 'mdi-account-group',
                    text: 'app.competitions',
                },
            ],
        }
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters(['decoded_token']),
        ...mapState(['features', 'is_system_user', 'token']),
    },
    watch: {
        features: {
            handler(features) {
                if (features.coach_admin || this.is_system_user) {
                    this.pages.push({
                        to: 'admin',
                        icon: 'mdi-security',
                        text: 'Administration',
                    })
                }
                if (
                    features.statistics_organization ||
                    features.statistics_units ||
                    features.statistics_coach ||
                    this.is_system_user
                ) {
                    this.pages.push({
                        to: 'statistics',
                        icon: 'mdi-chart-bar',
                        text: 'Statistik',
                    })
                }
            },
            deep: true,
        },
        token(value) {
            if (!value) this.internal_value = false
        },
    },
    methods: {
        logout() {
            this.set_state_property({
                property: 'token',
                data: null,
            })
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped></style>
