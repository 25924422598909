<template>
    <v-container style="max-width: 500px; position: relative;">
        <v-dialog
            v-model="show_dialog"
            persistent
            width="500"
        >
            <v-card>
                <v-card-title>
                    {{ editing ? translate('app.edit') : translate('app.create') }}
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="saveResource">
                        <v-text-field
                            v-model="resource.name"
                            :label="translate('app.name')"
                            required
                        ></v-text-field>
                        <v-textarea
                            v-model="resource.description"
                            :label="translate('app.description')"
                        ></v-textarea>
                        <v-btn type="submit" class="mr-12" color="primary">{{ editing ? translate('app.save') : translate('app.create') }}</v-btn>
                        <v-btn @click="cancelEdit">{{ translate('app.cancel') }}</v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-card-title>
            <h2>{{ translate('app.competitions') }}</h2>
            <v-spacer/>
            <v-btn
                color="success"
                @click="show_dialog=true"
                class="mb-6"
            >
                                {{ translate('app.new') }}
            </v-btn>
        </v-card-title>
        <v-row
            v-for="resource in competitions._items"
            :key="resource._id"
            class="mb-3"
        >
            <v-card
                width="500px"
            >
                <v-card-title>
                    {{ resource.name }}
                </v-card-title>
                <v-card-text>{{ resource.description }}</v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        @click="editResource(resource)">
                        {{ translate('app.edit') }}
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        color="error"
                        @click="deleteResource(resource)"
                    >
                        {{ translate('app.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>

    </v-container>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {api_mixin} from '@/mixins/services/api_mixin'
import {locales_mixin} from "@/mixins/services/locales_mixin";

export default {
    name: "Competitions",
    props: {},
    mixins: [api_mixin, locales_mixin],
    components: {},
    data() {
        return {
            resources: [],
            resource: {},
            editing: false,
            show_dialog: false,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['competitions']),
    },
    watch: {},
    methods: {
        fetchResources() {
            this.api_get({
                url: 'x-eld--my-competitions',
                params: {
                    where: {
                        user: this.decoded_token.u_id
                    },
                    sort: "name",
                    max_result: 50,
                },
                commit: true,
                property: "competitions",
            })
        },
        async saveResource() {
            if (this.editing) {
                await this.api_put({
                    url: 'x-eld--my-competitions',
                    data: this.resource,
                })
            } else {
                await this.api_post({
                    url: 'x-eld--my-competitions',
                    data: this.resource,
                })
            }
            this.cancelEdit()
            this.fetchResources()
        },
        editResource(resource) {
            this.editing = true
            this.show_dialog = true
            this.resource = {...resource}
            delete this.resource.user_name
        },
        async deleteResource(resource) {
            if (confirm(this.translate('app.confirm_delete'))) {
                await this.api_delete({
                    url: 'x-eld--my-competitions',
                    data: resource
                })
                this.cancelEdit()
                this.fetchResources()
            }
        },
        cancelEdit() {
            this.editing = false
            this.show_dialog = false
            this.resetForm()
        },
        resetForm() {
            this.resource = {
                name: '',
                description: '',
                user: this.decoded_token.u_id,
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
        this.resetForm()
    },
    mounted() {
        this.fetchResources()
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
.card {
    margin-top: 20px
}
</style>